<template>
  <div>
    <b-card no-body :style="f_calculateGeneralStyle()" :class="[d_mainFullScreen ?'full-screen-mode' :'normal-mode']">
      <b-row style="margin: 10px;" v-if="d_layerDetails.show">
        <b-col cols="12">
          <w-table2 :p_tableDetails="d_layerDetails"></w-table2>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  default as WTable
} from '@/components/widgets/WTable';

import {
  default as WTable2
} from '@/components/widgets/WTable2';

import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from "moment";
import WTopMenu from '@/components/widgets/WTopMenu';
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import WmanagerService from '@/services/wmanager';
import WdmService from '@/services/wdm';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
  name: 'LayerDetails',
  components: {
    Modal,
    WebBottom,
    WTopMenu,
    vSelect,
    WisdomDataModal,
    WdmParameters,
    WTable,
    WTable2
  },
  computed: mapGetters({
    help: 'help',
    device: 'device'
  }),
  data () {
    return {
      d_timerControlUpdate: { 'timer': 0, 'status': 'passive' },
      d_layerDetails: {
        'sand_watch': false,
        'updated': 0,
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'show': true,
        'timeout': 0,
        'table_header': [],
        'table': [],
        'filtered_count': 0,
        'filtered_table': [],
        'show_columns': [],
        'count': 0,
        'search_text': '',
        'search_index_list': []
      },
      d_mainFullScreen: false,
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    if (this.d_wdm22 && this.d_wdm22.id) {
      /*
      this.d_timerControlUpdate.timer = setInterval(function () {
        // console.log("this.d_project_id ", this.d_project_id);
        if (this.d_timerControlUpdate.status === 'passive') {
          this.f_getLayerList();
        }
      }.bind(this), 30000);
      */
      this.f_getLayerList();
      // this.f_getUserWdmList();
    }
    // this.f_getWdmrTreeList();
    this.d_wdmrToken = JSON.parse(localStorage.getItem('wdmr_token'));
  },
  mounted () {},
  destroyed () {
    // window.clearInterval(this.d_timerControlUpdate.timer);
  },
  methods: {
    f_calculateGeneralStyle: function () {
      let style = '';
      let height = 'height: ' + (window.innerHeight - 50).toString() + 'px;';
      style += height;
      return style;
    },
    f_getLayerList: function () {
      this.d_timerControlUpdate.status = 'active';
      let query = 'list_type=table_with_data_profile';
      // let query = 'list_type=table';
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman listesi hazırlanıyor. Lütfen bekleyiniz' } });
      this.d_layerDetails.sand_watch = true;
      WmanagerService.layer_list(query)
        .then(resp => {
          this.d_layerDetails.sand_watch = false;
          this.d_timerControlUpdate.status = 'passive';
          if (resp.data.status_code === "3000") {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_layerDetails.filtered_count = resp.data.table.length;
            this.d_layerDetails.table = resp.data.table;
            this.d_layerDetails.table_header = resp.data.table_header;
            this.d_layerDetails.count = this.d_layerDetails.table.length;
            this.d_layerDetails.show = true;
            if (this.d_layerDetails.updated === 0) {
              this.d_layerDetails.updated = 1;
            } else {
              this.d_layerDetails.updated = 0;
            }
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
  },
  watch: {}
};

</script>

<style type="text/css">
#user-search-list-row:hover {
  background-color: #92c9ea;
}

#user-search-list-selected-row:hover {
  background-color: #e29494;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>


import { render, staticRenderFns } from "./layerprofiledetails.vue?vue&type=template&id=56037d71"
import script from "./layerprofiledetails.vue?vue&type=script&lang=js"
export * from "./layerprofiledetails.vue?vue&type=script&lang=js"
import style0 from "./layerprofiledetails.vue?vue&type=style&index=0&id=56037d71&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports